body{
  background-color: #502a50;
}

.App {
  margin: 0 auto;
  /* max-width: 1440px; */
}

.mw{
  /* max-width: 1440px; */
  margin: 0 auto;
}
